import Routes from "../../../components/App/Routes";
import { ICaseStudyHeroData } from "../../../components/CaseStudy/CaseStudyHero";
import { ICaseStudyTileData } from "../../../components/CaseStudy/TileList/CaseStudyTile";
import {
  CoreDynamicComponents,
  CSDynamicComponents,
  IDynamicCaseStudyPageConfig,
  IImageData,
  PageDynamicComponents,
} from "../../DynamicConfigTypes";
import { ServiceIcons } from "../../models/Services";

import hero_mobi_src from "../../../assets/case_study/homerun/hero_mobi.png";
import hero_src from "../../../assets/case_study/homerun/hero.jpeg";
import hero_logo_src from "../../../assets/case_study/homerun/carousel_logo.png";
import tile_src from "../../../assets/case_study/homerun/tile.png";

import full_0_src from "../../../assets/case_study/homerun/content_full.png";
import full_1_src from "../../../assets/case_study/homerun/HOMERUN1.png";
import full_2_src from "../../../assets/case_study/homerun/HOMERUN2.png";
import full_3_src from "../../../assets/case_study/homerun/HOMERUN3.png";
import full_4_src from "../../../assets/case_study/homerun/HOMERUN4.png";
import full_5_src from "../../../assets/case_study/homerun/HOMERUN5.png";
import full_6_src from "../../../assets/case_study/homerun/HOMERUN6.png";
import full_7_src from "../../../assets/case_study/homerun/HOMERUN7.png";

import styles from "../../../pages/CaseStudy.module.css";
import pageStyles from "../../../components/Page/Page.module.css";

const cs_title = "HOMERUN Delivery";
const cs_route = Routes.Homerun;
/**
 * The Hero reference.<br />
 * Used in the:<br />
 * * Home Case Studies Carousel<br />
 * * Works Case Studies Heroes List<br />
 * * Case Study Pages themselves<br />
 */
const cs_hero: ICaseStudyHeroData = {
  img: hero_src,
  mobi_img: hero_mobi_src,
  title: cs_title,
  description: `
  The Beer Guy becomes HOMERUN: a complete brand teardown and rebuild with zero disruption to daily operations and service. <a href='${Routes.Homerun}'>Read More</a>`,
  services: [
    ServiceIcons.ContentMarketing,
    ServiceIcons.Advertising,
    ServiceIcons.SocialMedia,
    ServiceIcons.DesignBranding,
  ],
  logo: hero_logo_src,
  link: cs_route,
};

const cs_tile: ICaseStudyTileData = {
  img: tile_src,
  title: cs_title,
  subtitle: "Delivering a brand new identity for a beloved Canadian brand.",
  link: cs_route,
};

/**
 * The Case Study Config used across the App.
 */
const HomerunDynConfig: IDynamicCaseStudyPageConfig = {
  title: cs_title,
  slug: "diva-cup",
  isInHomeCarousel: false,
  hero: cs_hero,
  tile: cs_tile,
  route: cs_route,
  data: [
    {
      type: PageDynamicComponents.WordMarkLink,
      data: {
        className: [pageStyles.CenterAlign, pageStyles.HomeLink].join(" "),
      },
    },
    {
      type: CSDynamicComponents.CaseStudyHero,
      data: {
        ...cs_hero,
        style: { marginTop: "30px" },
        titleClass: [pageStyles.h1].join(" "),
        descriptionClass: [pageStyles.p2].join(" "),
        featuredClass: [pageStyles.p2].join(" "),
        showReadMore: false,
        doesTitleLink: false,
        showLogo: true,
        logoClassName: styles.MarginTop,
      } as ICaseStudyHeroData,
    },
    {
      type: PageDynamicComponents.TextWithHeader,
      data: {
        className: [
          pageStyles.p2,
          pageStyles.ThirdFourthAlign,
          pageStyles.AfterHeroFix,
        ].join(" "),
        textContent: `
          Ontarians love this guy. Since 2001, The Beer Guy has offered reliable, same-day delivery of the suds we love with no hidden markups or delivery fees. Two very well received rarities in the home delivery industry. Expansion across the province was inevitable and swift with fine spirits, wine and food eventually being added to The Beer Guy’s delivery offering. The company soon outgrew The Beer Guy moniker. And with expansion brought rivals. Relaxed alcohol delivery legislation in the province drove up competition. The Beer Guy looked to rebrand itself under a new name and brand platform that would build brand awareness and preference. Smith + Mason was entrusted with this massive undertaking. 
          <br />
          <br />
          We started by looking at and understanding the reasons why Ontarians use an alcohol delivery service (and the reasons why they don’t). And not surprisingly, convenience and cost were twin and competing motivators. 
          <br />
          <br />
          So, The Beer Guy became HOMERUN Delivery: a reliable home delivery service for consumers looking to save themselves both time and money. Because when you’re smart and choose to use a HOMERUN Delivery you’re saving both time and money… well, that’s a homerun, isn’t it? 
          <br />
          <br />
          This was a complete and total transformation. New name, new face, new tagline and all the supporting bits and bobs were developed to reinforce HOMERUN as the first choice for home alcohol delivery. With HOMERUN, consumers will quite literally “Never Run Out” – of the house or of the drinks they love. 
          <br />
          <br />
          From van wraps, swag, and delivery personnel uniforms, we left no cap unturned. Home delivery is a competitive space, so being bold and confident at every turn was essential and asserting the new brand as the leader in the space. 
          `,
      },
    },
    {
      type: CSDynamicComponents.CaseStudyFullBleedImage,
      data: {
        src: full_0_src,
        className: [styles.SectionVerticalSpace, styles.FillContentImage].join(
          " "
        ),
      } as IImageData,
    },
    {
      type: CSDynamicComponents.CaseStudyFullBleedImage,
      data: {
        src: full_1_src,
        className: [styles.SectionVerticalSpace, styles.FillContentImage].join(
          " "
        ),
      } as IImageData,
    },
    {
      type: CSDynamicComponents.CaseStudyFullBleedImage,
      data: {
        src: full_2_src,
        className: [styles.SectionVerticalSpace, styles.FillContentImage].join(
          " "
        ),
      } as IImageData,
    },
    {
      type: CSDynamicComponents.CaseStudyFullBleedImage,
      data: {
        src: full_3_src,
        className: [styles.SectionVerticalSpace, styles.FillContentImage].join(
          " "
        ),
      } as IImageData,
    },
    {
      type: CSDynamicComponents.CaseStudyFullBleedImage,
      data: {
        src: full_4_src,
        className: [styles.SectionVerticalSpace, styles.FillContentImage].join(
          " "
        ),
      } as IImageData,
    },
    {
      type: CSDynamicComponents.CaseStudyFullBleedImage,
      data: {
        src: full_5_src,
        className: [styles.SectionVerticalSpace, styles.FillContentImage].join(
          " "
        ),
      } as IImageData,
    },
    {
      type: CSDynamicComponents.CaseStudyFullBleedImage,
      data: {
        src: full_6_src,
        className: [styles.SectionVerticalSpace, styles.FillContentImage].join(
          " "
        ),
      } as IImageData,
    },
    {
      type: CSDynamicComponents.CaseStudyFullBleedImage,
      data: {
        src: full_7_src,
        className: [styles.SectionVerticalSpace, styles.FillContentImage].join(
          " "
        ),
      } as IImageData,
    },
    {
      type: CoreDynamicComponents.Button,
      data: {
        label: "See All Projects",
        link: Routes.Work,
        className: [pageStyles.CenterAlign, styles.SectionVerticalSpace].join(
          " "
        ),
      },
    },
    {
      type: PageDynamicComponents.PageDivider,
    },
    {
      type: PageDynamicComponents.PageFooter,
    },
  ],
};

export default HomerunDynConfig;
