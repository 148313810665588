const Routes = {
  Home: "/",
  About: "about",
  Services: "services",
  Work: "work",
  Biosheer: "work/biosheer",
  Vyta: "work/vyta",
  HunterDouglas: "work/hunterDouglas",
  Faw: "work/faw",
  Drop: "work/drop",
  DivaCup: "work/diva-cup",
  Hunter: "work/hunter-douglas",
  Homerun: "work/homerun",
  Privacy: "privacy",
};

export default Routes;
