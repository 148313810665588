import styles from "./CaseStudyCarousel.module.css";
import pageStyles from "../Page/Page.module.css";
import CarouselBasic from "./CarouselBasic";
import {
  CSSProperties,
  FunctionComponent,
  ReactElement,
  useContext,
} from "react";
import ImageWithOverlay from "../Page/ImageWithOverlay";
import { useNavigate } from "react-router";
import { useCaptureLinkClicksFromHTML } from "../../hooks/useCaptureLinkClicks";
import AppContext from "../App/AppContext";
import { Link } from "react-router-dom";
import { IBaseComponentData } from "../../config/DynamicConfigTypes";

export interface ICaseStudyCarouselItemData {
  title: string;
  text: string;
  img: string;
  link: string;
  logo: string;
  mobi_img: string;
  showLogo?: boolean;
  logoClassName?: string;
}
export interface ICaseStudyCarouselData extends IBaseComponentData {
  caseStudies: (ICaseStudyCarouselItemData | undefined)[];
  headerClass?: string;
  textClass?: string;
  featuredClass?: string;
  featuredLabel?: string;
  featedStyle?: CSSProperties;
}

export interface ICaseStudyCarouselProps extends ICaseStudyCarouselData {}

export const CaseStudyCarousel: FunctionComponent<ICaseStudyCarouselProps> = ({
  className = "",
  style = {},
  caseStudies,
  headerClass = "",
  textClass = "",
  featuredClass = "",
  featuredLabel = "",
  featedStyle,
}) => {
  const { isMobile } = useContext(AppContext);
  const navigate = useNavigate();
  const captureLinkClicks = useCaptureLinkClicksFromHTML();
  let featuredLabelEl: ReactElement | null = null;
  if (featuredLabel && featuredLabel !== "") {
    featuredLabelEl = (
      <div className={featuredClass} style={featedStyle}>
        {featuredLabel}
      </div>
    );
  }
  const slides = caseStudies.map((caseStudy, index) => {
    const showLogo = caseStudy?.showLogo;
    const logoClassName = caseStudy?.logoClassName;
    if (caseStudy !== undefined) {
      const onItemClick = () => {
        navigate("/" + caseStudy.link);
      };
      return (
        <div className={styles.CarouselItem} key={index}>
          <div>
            <ImageWithOverlay
              src={isMobile ? caseStudy.mobi_img : caseStudy.img}
              imageClassName={styles.CarouselImage}
              onClick={onItemClick}
            />
            {showLogo ? (
              <img
                className={[styles.CarouselLogo, logoClassName].join(" ")}
                src={caseStudy.logo}
                alt=""
              />
            ) : null}
          </div>
          <section className={pageStyles.ContentAlign}>
            {featuredLabelEl}
            <Link to={"/" + caseStudy.link} className={pageStyles.NoUnderline}>
              <h1 className={headerClass}>{caseStudy.title}</h1>
            </Link>
            <div
              className={textClass}
              dangerouslySetInnerHTML={{ __html: caseStudy.text || "" }}
              onClick={captureLinkClicks}
            />
          </section>
        </div>
      );
    } else {
      return null;
    }
  });

  return (
    <CarouselBasic
      className={className}
      style={style}
      children={slides as []}
    ></CarouselBasic>
  );
};

export default CaseStudyCarousel;
