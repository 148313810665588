// TODO: Change to dyn config.

import Routes from "../../components/App/Routes";
import { IPageHeaderData } from "../../components/Page/PageHeader";
import {
  CSDynamicComponents,
  IDynamicPageConfig,
  PageDynamicComponents,
} from "../DynamicConfigTypes";

import pageStyles from "../../components/Page/Page.module.css";
import { ICaseStudyHeroData } from "../../components/CaseStudy/CaseStudyHero";
import VytaConfig from "./caseStudies/VytaConfig";
import FawConfig from "./caseStudies/FawConfig";
import HomerunConfig from "./caseStudies/HomerunConfig";
import DropConfig from "./caseStudies/DropConfig";

const page_title = "Our Work";

const WorkConfig: IDynamicPageConfig = {
  title: page_title,
  slug: "work",
  route: Routes.Work,
  data: [
    {
      type: PageDynamicComponents.WordMarkLink,
      data: {
        className: [pageStyles.CenterAlign, pageStyles.HomeLink].join(" "),
      },
    },
    {
      type: PageDynamicComponents.PageHeader,
      data: {
        content: page_title,
      } as IPageHeaderData,
    },
    {
      type: PageDynamicComponents.TextWithHeader,
      data: {
        className: [pageStyles.ContentAlign, pageStyles.SectionSpace].join(" "),
        headerContent: `Let us show & tell you about our work`,
        headerContentClass: pageStyles.h1,
        textContent: `We believe getting to great work
          requires great client collaborators, who
          believe in the work and the impact it will
          have in their category and the lives of
          their customers. That’s what we call,
          working with purpose for a better
          tomorrow. Explore some of the work
          we’ve done for some of our clients
          below.`,
        textContentClass: pageStyles.p2,
      },
    },
    {
      type: CSDynamicComponents.CaseStudyHero,
      data: {
        ...DropConfig.hero,
        imgClickable: true,
      },
    },
    // {
    //   type: CSDynamicComponents.CaseStudyHero,
    //   data: {
    //     ...FawConfig.hero,
    //     imgClickable: true,
    //   },
    // },
    {
      type: CSDynamicComponents.CaseStudyHero,
      data: {
        ...HomerunConfig.hero,
        imgClickable: true,
      },
    },
    {
      type: CSDynamicComponents.CaseStudyHero,
      data: {
        ...VytaConfig.hero,
        imgClickable: true,
      },
    },
    { type: CSDynamicComponents.FilteredTileList },
    {
      type: PageDynamicComponents.PageDivider,
      data: {
        style: { marginTop: "200px" },
      },
    },
    { type: PageDynamicComponents.PageFooter },
  ],
};
export default WorkConfig;
