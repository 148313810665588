import Routes from "../../../components/App/Routes";
import { ICaseStudyHeroData } from "../../../components/CaseStudy/CaseStudyHero";
import { ICaseStudyTileData } from "../../../components/CaseStudy/TileList/CaseStudyTile";
import {
  CoreDynamicComponents,
  CSDynamicComponents,
  IDynamicCaseStudyPageConfig,
  IImageData,
  PageDynamicComponents,
} from "../../DynamicConfigTypes";
import { ServiceIcons } from "../../models/Services";

import hero_mobi_src from "../../../assets/case_study/divacup/hero_mobi.png";
import hero_src from "../../../assets/case_study/divacup/hero.png";
import hero_logo_src from "../../../assets/case_study/divacup/carousel_logo.png";
import tile_src from "../../../assets/case_study/divacup/tile.png";

import full_1_src from "../../../assets/case_study/divacup/DIVA_01.png";
import full_2_src from "../../../assets/case_study/divacup/DIVA_02.png";
import full_3_src from "../../../assets/case_study/divacup/Diva_05.png";
import full_4_src from "../../../assets/case_study/divacup/DIVA_04.png";

import styles from "../../../pages/CaseStudy.module.css";
import pageStyles from "../../../components/Page/Page.module.css";

const cs_title = "DivaCup®";
const cs_route = Routes.DivaCup;
/**
 * The Hero reference.<br />
 * Used in the:<br />
 * * Home Case Studies Carousel<br />
 * * Works Case Studies Heroes List<br />
 * * Case Study Pages themselves<br />
 */
const cs_hero: ICaseStudyHeroData = {
  img: hero_src,
  mobi_img: hero_mobi_src,
  title: cs_title,
  description: `
  Developing a consistent global shopping experience. <a href='${Routes.DivaCup}'>Read More</a>`,
  services: [ServiceIcons.WebsiteDev, ServiceIcons.CRM],
  logo: hero_logo_src,
  link: cs_route,
};

const cs_tile: ICaseStudyTileData = {
  img: tile_src,
  title: cs_title,
  subtitle:
    "Creating a digital first, integrated campaign supporting the re-launch of the brand.",
  link: cs_route,
};

/**
 * The Case Study Config used across the App.
 */
const DivaCupDynConfig: IDynamicCaseStudyPageConfig = {
  title: cs_title,
  slug: "diva-cup",
  isInHomeCarousel: false,
  hero: cs_hero,
  tile: cs_tile,
  route: cs_route,
  data: [
    {
      type: PageDynamicComponents.WordMarkLink,
      data: {
        className: [pageStyles.CenterAlign, pageStyles.HomeLink].join(" "),
      },
    },
    {
      type: CSDynamicComponents.CaseStudyHero,
      data: {
        ...cs_hero,
        style: { marginTop: "30px" },
        titleClass: [pageStyles.h1].join(" "),
        descriptionClass: [pageStyles.p2].join(" "),
        featuredClass: [pageStyles.p2].join(" "),
        showReadMore: false,
        doesTitleLink: false,
        showLogo: false,
      } as ICaseStudyHeroData,
    },
    {
      type: PageDynamicComponents.TextWithHeader,
      data: {
        className: [
          pageStyles.p2,
          pageStyles.ThirdFourthAlign,
          pageStyles.AfterHeroFix,
        ].join(" "),
        textContent: `
          Diva International is revolutionary in the feminine hygiene category, providing women with a more economical and environmentally friendly way of managing their monthly period.  As DivaCup continued to expand its product offering in existing markets and to enter new ones, the business needed a scalable digital platform. One that would grow with DivaCup’s evolving business needs and adapt to global market requirements.
          <br />
          <br />
          With the goal of providing users a consistent user experience across markets, we developed a platform that allowed the brand to centrally manage content on a global scale. One that could adapt and manage an ever-evolving list of retail partners while making it easy for users to learn more about DivaCup, shop direct on the website or locate local retailers.
          <br />
          <br />
          Built on a Wordpress platform, the CMS framework enabled the management of all page content and product SKU availability by market (accommodating double-byte and special character sets) centralizing and simplifying data management and synchronization. 
          <br />
          <br />
          A customized Shopify storefront enabled direct-to-consumer sales within North America and directed users to shop locally available DivaCup products on preferred e-tailer websites in global markets where direct shipping was not available. 
          <br />
          <br />
          We successfully engineered a consistent global customer experience that empowered women to join the “Inner Revolution”, allowing them to experience and shop DivaCup the world over. 
        `,
      },
    },
    {
      type: CSDynamicComponents.CaseStudyFullBleedImage,
      data: {
        src: full_1_src,
        className: [styles.SectionVerticalSpace].join(" "),
      } as IImageData,
    },
    {
      type: CSDynamicComponents.CaseStudyFullBleedImage,
      data: {
        src: full_2_src,
        className: [styles.SectionVerticalSpace].join(" "),
      } as IImageData,
    },
    {
      type: CSDynamicComponents.CaseStudyFullBleedImage,
      data: {
        src: full_3_src,
        className: [styles.SectionVerticalSpace].join(" "),
      } as IImageData,
    },
    {
      type: CSDynamicComponents.CaseStudyFullBleedImage,
      data: {
        src: full_4_src,
        className: [styles.SectionVerticalSpace].join(" "),
      } as IImageData,
    },
    {
      type: CoreDynamicComponents.Button,
      data: {
        label: "See All Projects",
        link: Routes.Work,
        className: [pageStyles.CenterAlign, styles.SectionVerticalSpace].join(
          " "
        ),
      },
    },
    {
      type: PageDynamicComponents.PageDivider,
    },
    {
      type: PageDynamicComponents.PageFooter,
    },
  ],
};

export default DivaCupDynConfig;
